<template>
  <div class="my-warp app_content">
    <div class="warp_body">


      <div v-if="userInfo.userType=='P'" class="info">
        <van-cell :value="userInfo.name" title="姓名"></van-cell>
        <van-cell value="普通用户" title="会员等级"></van-cell>
        <van-cell :value="userInfo.phone" title="手机号码" is-link></van-cell>
        <change-order-status-card :order-status="userInfo.orderStatus"></change-order-status-card>
        <van-cell :value="userInfo.workName" title="工种" is-link @click="getWorkTypeList"></van-cell>
        <van-cell :value="userInfo.level" title="等级" is-link @click="getLevelList"></van-cell>
        <van-cell :value="userInfo.price" title="价格" is-link></van-cell>
        <van-cell :value="userInfo.area" title="行政区划" is-link @click="getAreaList"></van-cell>

      </div>
      <div v-if="userInfo.userType=='C'" class="info">
        <van-cell :value="userInfo.companyName" title="单位名称"></van-cell>
        <van-cell value="普通用户" title="会员等级"></van-cell>
        <van-cell :value="userInfo.companyPhone" title="单位号码" is-link></van-cell>
        <van-cell :value="userInfo.companyAddress" title="单位地址" is-link @click="getWorkTypeList"></van-cell>
        <van-cell :value="userInfo.area" title="单位区域" is-link @click="getAreaList"></van-cell>
      </div>

      <div class="van-buttonDiv">
        <van-button round size="normal" url="cancelAccount" style="width: 60%" color="#4DBAFD">注销账号</van-button>
      </div>

    </div>

    <van-popup v-model:show="showLevelPicker" position="bottom">
      <van-picker
          :columns="levelList"
          @confirm="onConfirmLevel"
          @cancel="showLevelPicker = false"
          :defaultIndex="defaultIndex"
      />
    </van-popup>
    <van-popup v-model:show="showWorkTypePicker" position="bottom">
      <van-picker
          :columns="workTypeList"
          @confirm="onConfirmWorkType"
          @cancel="showWorkTypePicker = false"
          :defaultIndex="defaultIndex"
      />
    </van-popup>
    <van-popup v-model:show="showAreaPicker" position="bottom">
      <van-picker
          :columns="areaList"
          @confirm="onConfirmArea"
          @cancel="showAreaPicker = false"
          :defaultIndex="defaultIndex"
      />
    </van-popup>


  </div>
</template>

<script>
import {onMounted, reactive, toRefs} from 'vue'
import {useRouter} from 'vue-router'
import API from "../api/User";
import req from "../request/request";
import ChangeOrderStatusCard from "@/components/common/changeOrderStatusCard";

export default {
  name: 'PersonalInfo',
  components: {ChangeOrderStatusCard},
  setup() {
    onMounted(() => {
      methodsMap.getInfo()
    });
    const router = useRouter()
    const data = reactive({
      defaultIndex: 0,
      userInfo: {},
      levelList: [],
      workTypeList: [],
      areaList: [],
      showLevelPicker: false,
      showWorkTypePicker: false,
      showAreaPicker: false,
    })
    let methodsMap = {
      getWorkTypeList: () => {
        data.showWorkTypePicker = true
        req.request(API.workTypeList, null, {method: 'GET'})
            .then((res) => {
              data.workTypeList = res.data.data
              for (var i = 0; i < data.workTypeList.length; i++) {
                if (data.workTypeList[i].text == data.userInfo.workName) {
                  data.defaultIndex = i;
                }
              }
            })
      },
      onConfirmWorkType: () => {
        data.showWorkTypePicker = false
      },
      onConfirmLevel: (item) => {
        data.showLevelPicker = false
        data.levelObj = item
        console.log('levelObj', item)
      },
      onConfirmArea: (item) => {
        data.showAreaPicker = false
        console.log('area', item)
      },
      getLevelList: () => {
        data.showLevelPicker = true
        req.request(API.levelList, null, {method: 'GET'})
            .then((res) => {
              data.levelList = res.data.data
              for (var i = 0; i < data.levelList.length; i++) {
                if (data.levelList[i].text == data.userInfo.level) {
                  data.defaultIndex = i;
                }
              }
            })
      },
      getAreaList: () => {
        data.showAreaPicker = true
        req.request(API.areaList, null, {method: 'GET'})
            .then((res) => {
              data.areaList = res.data.data
              for (var i = 0; i < data.areaList.length; i++) {
                if (data.areaList[i].text == data.userInfo.area) {
                  data.defaultIndex = i;
                }
              }
            })
      },

      getInfo: () => {
        req.request(API.getDetailUserInfo, null, {method: 'GET'}).then(
            (res) => {
              data.userInfo = res.data.data
              let val = data.userInfo.phone.replace(/^(\d{3})\d{4}(\d{4})$/, '$1****$2')
              data.userInfo.phone = val
            }
        )
      },
      editMyInfo: () => {
        if (data.userInfo.uid) {
          router.push("/editMyInfo")
        } else {
          router.push("/RegisterSelect")
        }
      },
      onClickLeft: () => {history.back()},
    }
    return {
      ...methodsMap,
      ...toRefs(data)
    }
  }
}
</script>
<style lang="less" scoped>

.app_content {

  .warp_body {
    padding: 3px;
    .info {

    }
    .van-buttonDiv{
      text-align: center;
      margin-top: 40px;
    }
  }


}
</style>