<template>
  <van-cell center title="接单状态">
    <template #right-icon>
      <van-switch v-model="orderStatus" active-color="#4DBAFD" size="24" @click="updateStatus(orderStatus)"/>
    </template>
  </van-cell>
</template>

<script>
import {reactive, toRefs} from 'vue'
import {Dialog, Toast} from "vant";
import req from "../../request/request";
import router from "../../router";
import API_USER from "../../api/UserReq";

export default {
  name: 'changeOrderStatusCard',
  components: {},
  props: {
    orderStatus: {
      type: Boolean,
      default: () => {
        return true
      }
    }
  },
  setup() {


    let data = reactive({});

    let methodMap = {

      updateStatus: (val) => {

        console.log("switch", val)

        if (val) {
          Dialog.confirm({
            title: '业务提示',
            message: '是否开启接单？',
          }).then(() => {
            API_USER.editUser(val)
          });
        } else {
          Dialog.confirm({
            title: '业务提示',
            message: '是否关闭接单？',
          }).then(() => {
            API_USER.editUser(val)
          });
        }


      },

    }


    return {
      ...toRefs(data),
      ...methodMap
    }
  }
}
</script>

<style lang="less">


.singleOrder {
  background: #FFF;
  margin: 5px 7px;
  padding: 10px;
  border-radius: 12px;
  position: relative;
  display: flex;
  justify-content: space-between;
  height: 80px;

  .personInfo {
    position: absolute;
    left: 8px;
    font-size: 11px;

    span {
      padding: 2px;
    }

    .orderDiv {
      color: gray;
    }
  }

  .handle {
    position: absolute;
    right: 8px;

  }
}

</style>